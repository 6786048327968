import { fixBody } from '@/helpers/variables/index'

import {
  FIX_BODY,
  SHOW_GLOBAL_LOADER,
  POPUP_TEXT_CONTENT,
  SHOW_POPUP_TEXT_CONTENT,
  CHANGE_CURRENT_LANG,
  CHANGE_SHOW_LANG_LIST
} from '../mutation-types'

const state = {
  globalLoader: false,
  fixBody: false,
  currentLang: null,
  showLangList: ''
}

const getters = {
  fixBody: state => state.fixBody,
  globalLoader: state => state.globalLoader,

  currentLang: state => state.currentLang,
  showLangList: state => state.showLangList
}

const mutations = {
  [SHOW_GLOBAL_LOADER] (state, status) {
    state.globalLoader = status
  },
  [FIX_BODY] (state, param) {
    const body = document.querySelector('body')
    if (param) {
      body.classList.add('fix')
    } else {
      body.classList.remove('fix')
    }
    state.fixBody = param
  },
  [POPUP_TEXT_CONTENT] (state, content) {
    state.popup = content
    fixBody(state.popup.show)
  },
  [SHOW_POPUP_TEXT_CONTENT] (state, status) {
    state.popupTextShow = status
  },
  [CHANGE_CURRENT_LANG] (state, status) {
    state.currentLang = status
  },
  [CHANGE_SHOW_LANG_LIST] (state, status) {
    state.showLangList = status
  }
}

export default {
  state,
  getters,
  mutations
}
