<template>
  <div class="accordion">
    <div class="accordion-head" @click="toggleCardState" :class="{ 'active': expanded}">
      <div class="accordion-title">
        {{ $t(`basicList.item${index}.title`) }}
      </div>
      <div class="accordion-icon">
        <div class="pluss">
          <div class="toggle" :class="{'open': !expanded}"></div>
        </div>
      </div>
    </div>

    <VueSlideToggle :open="expanded" tag="div" :duration="450">
      <div class="accordion-content">
        <ul class="accordion-list">
          <li class="accordion-item" v-for="(subItem,subIndex) in subItemList" :key="subItem.id">
            {{ $t(`basicList.item${index}.list.subItem${subIndex}.item`) }}
          </li>
        </ul>
      </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle'

export default {
  name: 'Accordion',
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  components: {
    VueSlideToggle
  },
  computed: {
    subItemList () {
      return parseInt(this.$t(`basicList.item${this.index}.list.totalSubItems`))
    }
  },
  created () {
    if (this.index === 1) {
      this.expanded = true
    }
  },
  methods: {
    toggleCardState () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion{
  &-head{
    cursor: pointer;
    position: relative;
    color: #333;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    display: block;
    padding: 14px 20px;
    transition: background-color $trans, color $trans, border-color $trans;
    @include respondTo(600px){
      padding: 10px;
    }
    &.active,
    &:hover {
      background-color: #EEF0F3;
      border-color: #EEF0F3;
      color: #333;
    }
  }
  &-icon{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    $w: 12px;
    $h: 2px;
    $bg: #082C23;
    $transition: 0.25s all cubic-bezier(0.17, 0.67, 0.09, 0.97);
    .wrap {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .toggle {
      width: $w;
      height: $h;
      background: $bg;
      position: relative;
      transition: $transition;
      &.open::after {
        transform: rotate(90deg);
        opacity: 1;
      }
      &.open {
        transform: rotate(90deg);
      }
      &::after {
        content: "";
        width: $w;
        transition: $transition;
        transition-delay: 0.1s;
        height: $h;
        position: absolute;
        opacity: 0;
        background: $bg;
      }
    }
  }
  &-content{
    font-size: 14px;
    line-height: 20px;
    padding: 20px 35px;
    background-color: #fafafa;
    border: 1px solid #EEF0F3;
    p{
      font-size: 14px;
      line-height: 20px;
    }
    @include respondTo(600px){
      padding: 10px 15px;
    }
  }
  &-title{
    font-size: 16px;
    line-height: 20px;
    max-width: 95%;
    @include respondTo(600px){
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
