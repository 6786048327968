<template>
  <div id="app" class="app">
    <AppBanner />
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <AppHeader />
          <div class="wrapper-content">
            <transition name="component-fade" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
        <div class="wrapper-bottom">
          <AppFooter />
        </div>
      </div>
    </div>
  </div>
</template>

<!--// global styles-->
<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import AppHeader from '@/components/features/AppHeader.vue'
import AppFooter from '@/components/features/AppFooter.vue'
import AppBanner from '@/components/features/AppBanner.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    AppBanner
  }
}
</script>
