import Vue from 'vue'
import Router from 'vue-router'

// modules
import Home from '@/modules/home/index.vue'
import About from '@/modules/about/index.vue'
import Benefits from '@/modules/benefits/index.vue'
import Features from '@/modules/features/index.vue'
import FAQ from '@/modules/faq/index.vue'
import Partners from '@/modules/partners/index.vue'
import PrivacyPolicy from '@/modules/footer/privacy-policy/index.vue'
import LicenseDeadlines from '@/modules/license-deadlines/index.vue'
import TermsOfUse from '@/modules/footer/terms-of-use/index.vue'
import AppLicense from '@/modules/footer/app-license/index.vue'
import NotFound from '@/modules/not-found/index.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang(.{2})?',
      name: 'home',
      component: Home
    },
    {
      path: '/:lang?/about',
      name: 'about',
      component: About
    },
    {
      path: '/:lang?/benefits',
      name: 'benefits',
      component: Benefits
    },
    {
      path: '/:lang?/features',
      name: 'features',
      component: Features
    },
    {
      path: '/:lang?/faq',
      name: 'faq',
      component: FAQ
    },
    {
      path: '/:lang?/academy',
      name: 'academy',
      component: Partners
    },
    {
      path: '/:lang?/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy
    },
    {
      path: '/:lang?/license-deadlines',
      name: 'license-deadlines',
      component: LicenseDeadlines
    },
    {
      path: '/:lang?/terms-of-use',
      name: 'terms-of-use',
      component: TermsOfUse
    },
    {
      path: '/:lang?/app-license',
      name: 'app-license',
      component: AppLicense
    },
    {
      path: '/:lang?/*',
      name: 'not-found',
      component: NotFound
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    const el = document.querySelector('.app')
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition
      } else {
        el.scrollLeft = 0
        el.scrollTop = 0

        return { x: 0, y: 0 }
      }
    }
  }
})

export default router
