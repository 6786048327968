<template>
  <div class="header" id="header">
    <div class="header-wrap">
      <div class="header-logo">
        <a href="#" @click.prevent="changeHeaderLogo">
          <img src="../../assets/img/home/liveleague-logo-black.png" alt="">
        </a>
      </div>
    </div>
    <div class="header-language">
      <tooltip-layout :maxWidth="160" position="left">
        <template slot="icon">
          <img :src="`/img/locales/${currentLanguage.urlPrefix}.png`" class="flag">
        </template>

        <ul>
          <li class="column"
              v-for="item in languages"
              :key="item.id">
            <a @click.prevent="changeLanguage(item)"
               href="#"
               :title="item.title"
               v-if="item.urlPrefix !== currentLanguage.urlPrefix">
                 <span class="language-item">
                   <img :alt="item.alt"
                         :src="`/img/locales/${item.urlPrefix}.png`"
                         class="flag"/>
                   <span class="language__text">{{ item.name }}</span>
                 </span>
            </a>
          </li>
        </ul>
      </tooltip-layout>
    </div>
    <div class="header-menu">
      <tooltip-layout :maxWidth="300"
                      position="left"
                      :arrow="false">
        <template slot="icon">
          <Burger/>
        </template>
        <HeaderNavigation/>
        <div class="header-menu__links">
          <AppLinks/>
        </div>
      </tooltip-layout>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import TooltipLayout from '@/components/atoms/TooltipLayout.vue'
import Burger from '@/components/atoms/Burger.vue'
import AppLinks from '@/components/features/AppLinks.vue'
import HeaderNavigation from '@/components/features/HeaderNavigation.vue'

export default {
  name: 'AppHeader',
  data () {
    return {
      isOpenMenu: false,
      isActive: false
    }
  },
  components: {
    TooltipLayout,
    Burger,
    AppLinks,
    HeaderNavigation
  },
  computed: {
    ...mapGetters({
      showLangList: 'system/showLangList',
      isShowLanguageList: 'system/isShowLanguageList'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage'
    ]),
    clientLiveLeagueLink () {
      return process.env.VUE_APP_CLIENT_LIVELEAGUE_LINK
    },
    clientLiveLeagueLoginLink () {
      return process.env.VUE_APP_CLIENT_LIVELEAGUE_LOGIN_LINK
    },
    clientLiveLeagueJoinLink () {
      return process.env.VUE_APP_CLIENT_LIVELEAGUE + '/info-leagues'
    }
  },
  methods: {
    ...mapMutations({
      changeCurrentLang: 'system/CHANGE_CURRENT_LANG',
      changeShowLangList: 'system/CHANGE_SHOW_LANG_LIST'
    }),
    changeHeaderLogo () {
      if (this.$route.name === 'home') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        this.$router.push({ name: 'home' }).catch(() => {
          console.log()
        })
      }
    },
    changeLanguage (language) {
      this.$setLanguage(language.code)
      setTimeout(() => {
        window.location.reload()
      }, 800)
    },
    openMenu () {
      this.isOpenMenu = !this.isOpenMenu
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background: #00b764;
  transition: background $trans;
  z-index: 10;
  height: 145px;
  position: relative;
  @include respondTo(800px) {
    height: 60px;
  }
  //wrap
  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respondTo(600px) {
      justify-content: flex-start;
      padding-left: 10px;
    }
  }

  &-logo {
    a {
      display: block;
      width: 160px;
      @include respondTo(800px) {
        width: 80px;
      }

      img {
        width: 130px;
        transition: transform $trans;
        @include respondTo(800px) {
          width: 60px;
          height: 60px;
        }
      }

      &:hover img {
        transform: scale(0.9);
      }
    }
  }

  &-language {
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    @include respondTo(600px) {
      right: 80px;
    }
  }

  &-lang {
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__name {
      margin-left: 10px;
    }
  }

  &-menu {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    @include respondTo(600px) {
      right: 20px;
    }
  }
}
</style>
