import { VueSlideToggle } from 'vue-slide-toggle'
import Accordion from './components/Accordion.vue'
import FaqAccordion from './components/FaqAccordion.vue'
export default {
  name: 'faq',
  data () {
    return {}
  },
  components: {
    VueSlideToggle,
    Accordion,
    FaqAccordion
  },
  computed: {
    faqListCount () {
      return parseInt(this.$t('faqList.totalItems'))
    },
    basicListCount () {
      return parseInt(this.$t('basicList.totalItems'))
    }
  }
}
