import DatePicker from 'vue2-datepicker'
import Icon from '@/components/atoms/Icon.vue'
import 'vue2-datepicker/index.css'
import moment from 'moment'

import en from 'vue2-datepicker/locale/en.js'
import es from 'vue2-datepicker/locale/es.js'
import de from 'vue2-datepicker/locale/de.js'
import hi from 'vue2-datepicker/locale/hi.js'
import jp from 'vue2-datepicker/locale/ja.js'
import fr from 'vue2-datepicker/locale/fr.js'
import ru from 'vue2-datepicker/locale/ru.js'
import zh from 'vue2-datepicker/locale/zh-cn.js'
import pt from 'vue2-datepicker/locale/pt.js'

import { mapGetters } from 'vuex'

export default {
  name: 'deadlines',
  data () {
    return {
      startDate: new Date(),
      teamRegistrationDeadline: null,
      playerRegistrationDeadline: null,
      uniformRegistrationDeadline: null,
      gameRegistrationDeadline: null,
      sponsorshipRegistrationDeadline: null,
      inclusiveSportPackageShipped: null,
      lang: en
    }
  },
  components: {
    DatePicker,
    Icon
  },
  computed: {
    ...mapGetters([
      'currentLanguage'
    ])
  },
  mounted () {
    this.setDates()
    this.setDatePickerCurrentLocale()
  },
  methods: {
    setDates () {
      this.teamRegistrationDeadline = moment(this.startDate).subtract(90, 'days').toDate()
      this.playerRegistrationDeadline = moment(this.startDate).subtract(76, 'days').toDate()
      this.uniformRegistrationDeadline = moment(this.startDate).subtract(71, 'days').toDate()
      this.gameRegistrationDeadline = moment(this.startDate).subtract(68, 'days').toDate()
      this.sponsorshipRegistrationDeadline = moment(this.startDate).subtract(38, 'days').toDate()
      this.inclusiveSportPackageShipped = moment(this.startDate).subtract(18, 'days').toDate()
    },
    openDatePicker () {
      this.$refs.datepicker.focus()
    },
    setDatePickerCurrentLocale () {
      switch (this.currentLanguage.urlPrefix) {
        case 'es':
          this.lang = es
          break
        case 'de':
          this.lang = de
          break
        case 'hi':
          this.lang = hi
          break
        case 'jp':
          this.lang = jp
          break
        case 'fr':
          this.lang = fr
          break
        case 'ru':
          this.lang = ru
          break
        case 'pt':
          this.lang = pt
          break
        case 'zh':
          this.lang = zh
          break
        default:
          this.lang = en
      }
    }
  }
}
