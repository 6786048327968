<template>
  <nav class="header-nav">
    <ul class="header-nav__list">
      <li>
        <router-link
          class="header-nav__item"
          :to="$localize({ name: 'home' })">
            <span class="header-nav__item-ico">
              <icon name="home-nav"></icon>
            </span>
          <div class="header-nav__item-label">
            {{ $t('navigation.home') }}
          </div>
        </router-link>
      </li>
      <li>
        <router-link
          class="header-nav__item"
          :to="$localize({ name: 'about' })">
          <span class="header-nav__item-ico">
             <icon name="home-about"></icon>
          </span>
          <div class="header-nav__item-label">
            {{ $t('navigation.about') }}
          </div>
        </router-link>
      </li>
      <li>
        <router-link
          class="header-nav__item"
          :to="$localize({ name: 'benefits' })">
            <span class="header-nav__item-ico">
              <icon name="home-benefits"></icon>
            </span>
          <div class="header-nav__item-label">
            {{ $t('navigation.benefits') }}
          </div>
        </router-link>
      </li>
      <li>
        <router-link
          class="header-nav__item"
          :to="$localize({ name: 'features' })">
            <span class="header-nav__item-ico">
              <icon name="home-features"></icon>
            </span>
          <div class="header-nav__item-label">
            {{ $t('navigation.features') }}
          </div>
        </router-link>
      </li>
      <li>
        <a :href="clientLiveLeagueJoinLink"
           target="_blank"
           class="header-nav__item">
            <span class="header-nav__item-ico">
               <icon name="join-league"></icon>
            </span>
          <div class="header-nav__item-label">
            {{ $t('joinOpenLeagueOrTeam') }}
          </div>
        </a>
      </li>
      <li>
        <router-link :to="$localize({ name: 'academy' })"
                     class="header-nav__item">
        <span class="header-nav__item-ico">
          <icon name="home-academy"></icon>
        </span>
        <div class="header-nav__item-label">
          {{ $t('navigation.academy') }}
        </div>
      </router-link>
      </li>
      <li>
        <a :href="clientLiveLeagueLink"
           target="_blank"
           class="header-nav__item">
            <span class="header-nav__item-ico">
              <icon name="league-register"></icon>
            </span>
          <div class="header-nav__item-label">
            {{ $t('register') }}
          </div>
        </a>
      </li>
      <li>
        <router-link
          class="header-nav__item"
          :to="$localize({ name: 'license-deadlines' })">
            <span class="header-nav__item-ico">
             <icon name="license-deadlines"></icon>
            </span>
          <div class="header-nav__item-label">
            {{ $t('navigationLicenseDeadlines') }}
          </div>
        </router-link>
      </li>
      <li>
        <router-link
          class="header-nav__item"
          :to="$localize({ name: 'faq' })">
             <span class="header-nav__item-ico">
              <icon name="faq"></icon>
             </span>
          <div class="header-nav__item-label">
            {{ $t('navigation.faq') }}
          </div>
        </router-link>
      </li>
      <li>
        <a
          target="_blank"
          class="header-nav__item"
          :href="clientLiveLeagueLoginLink">
            <span class="header-nav__item-ico">
             <icon name="login"></icon>
            </span>
          <div class="header-nav__item-label">
            {{ $t('navigation.login') }}
          </div>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue'

export default {
  name: 'HeaderNavigation',
  components: {
    Icon
  },
  computed: {
    clientLiveLeagueLink () {
      return process.env.VUE_APP_CLIENT_LIVELEAGUE_LINK
    },
    clientLiveLeagueLoginLink () {
      return process.env.VUE_APP_CLIENT_LIVELEAGUE_LOGIN_LINK
    },
    clientLiveLeagueJoinLink () {
      return process.env.VUE_APP_CLIENT_LIVELEAGUE + '/info-leagues'
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  &-nav{
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 8px 0;
    .tooltip-layout__wrap::after{
      display: none !important;
    }
    li:last-child .header-nav__item-label {
      border-bottom: none;
    }
    &__item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      padding: 0;
      margin: 0;
      height: 48px;
      position: relative;
      transition: background .2s ease-in-out;
      color: #111;
      &.router-link-exact-active{
        background: #dbebe4;
      }
      &-ico{
        width: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .65;
      }
      &-label{
        width: calc(100% - 60px);
        font-weight: 400;
        font-size: 14px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid rgba(0,0,0,.1);
        transition: border-color .2s ease-in-out;
      }
    }
  }
}
</style>
