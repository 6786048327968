import Vue from 'vue'
import SwiperCore, { Swiper as SwiperClass, Pagination, Navigation, EffectFade } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import '../../assets/scss/swiper-bundle.scss'
import '../../assets/scss/effect-fade.scss'

SwiperCore.use([EffectFade])

SwiperClass.use([Pagination, Navigation])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: 'home',
  data () {
    return {
      currentSlide: 0,
      isLastSlide: false,
      swiperOptions: {
        autoHeight: true,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          clickable: true,
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    changeSlide (index) {
      this.isLastSlide = index.activeIndex + 1 === index.slides.length
    }
  }
}
