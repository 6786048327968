<template>
  <div class="burger">
    <div class="burger-wrap">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Burger'
}
</script>

<style lang="scss" scoped>
.tooltip-layout.open {
  .burger {
    background: rgba(0, 0, 0, .1);
    &-wrap {
      span {
        &:first-child {
          opacity: 0;
          transform: translateY(-5px);
        }
        &:last-child {
          opacity: 0;
          transform: translateY(5px);
        }
        &:nth-child(3) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
.burger {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  transition: background $trans;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: rgba(0, 0, 0, .1);
  }
  &-wrap {
    width: 22px;
    height: 14px;
    position: relative;
    span {
      position: absolute;
      height: 2px;
      background: $white;
      transition: transform $trans, opacity $trans;
      left: 0;
      right: 0;
      &:first-child {
        transform: translateY(0);
        top: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 6px;
      }
      &:last-child {
        bottom: 0;
        transform: translateY(0);
      }
    }
  }
}
</style>
