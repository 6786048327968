// import {translation} from '@/helpers/translation';
// import {globalLoader} from '@/helpers/variables/index';

import {
} from '../mutation-types'

import {
} from '../action-types'

const state = {
  translations: null
}

const getters = {
  translations: state => state.translations
}

const actions = {
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
